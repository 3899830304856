
































import { mapGetters, mapState, mapActions } from 'vuex'
import { IAuthState, IAuthData } from '@/store/modules/auth/types'

const ForgotPasswordCard = () =>
  import('@/components/User/Cards/ForgotPasswordCard.vue')
const PasswordResetCard = () =>
  import('@/components/User/Cards/PasswordResetCard.vue')
const LoginCard = () => import('@/components/User/Cards/LoginCard.vue')

export default {
  name: 'LoginPage',
  components: { ForgotPasswordCard, PasswordResetCard, LoginCard },
  data: (): IAuthData => ({
    reset: false,
    forgotten: false
  }),
  computed: {
    ...mapState('auth', {
      authError: (state: IAuthState) => state.error,
      authStatus: (state: IAuthState) => state.status
    }),
    ...mapGetters('auth', ['isAuthenticated'])
  },
  watch: {
    async isAuthenticated(value): Promise<void> {
      if (value) {
        await this.$router.push({ name: 'DataLoader' })
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'PASSWORD_RESET_REQUEST',
      'AUTH_REQUEST',
      'CLEAR_ERROR'
    ]),
    async onLogin(username: string, password: string): Promise<void> {
      await this.AUTH_REQUEST(username, password)
    },
    onDismissed(): void {
      return this.CLEAR_ERROR()
    },
    async onResetPassword(email: string): Promise<void> {
      await this.PASSWORD_RESET_REQUEST(email)
      if (!this.authError) {
        this.reset = true
        this.forgotten = true
      }
    },
    async onReadyToLogin({ reset = false, forgotten = false } = {}): Promise<
      void
    > {
      this.reset = reset
      this.forgotten = forgotten
      this.onDismissed()
    }
  }
}
